// ==========[ BUTTONS ]==========

.btn {
  @include smallText(14px);
  padding: 14px 21px;
  border-radius: 10px;
  font-size: 14px;
}

.btn-primary {
  @include normal-button($grey-dark);

  &.orange {
    @include normal-button($orange);
  }

  &.mint {
    @include normal-button($mint);
  }

  &.blue {
    @include normal-button($blue);
  }

  &.purple {
    @include normal-button($purple);
  }

  &.green {
    @include normal-button($green);
  }

  &.yellow {
    @include normal-button($yellow);
  }

  &.white {
    color: $grey-dark;
    border: 2px solid $white;
    background-color: $white;

    &:hover {
      color: $white;
      border: 2px solid $grey-dark;
      background-color: $grey-dark;
    }
  }
}

.btn-secondary {
  @include border-button($grey-dark);

  &.orange {
    @include border-button($orange);
  }

  &.mint {
    @include border-button($mint);
  }

  &.blue {
    @include border-button($blue);
  }

  &.purple {
    @include border-button($purple);
  }

  &.green {
    @include border-button($green);
  }

  &.yellow {
    @include border-button($yellow);
  }

  &.white {
    color: $white;
    border: 2px solid $white;
    background-color: transparent;

    &:hover {
      color: $white;
      border: 2px solid $grey-dark;
      background-color: $grey-dark;
    }
  }
}

.btn-link-white {
  @include smallText(14px);
  color: $white;

  &:hover {
    color: $grey-light;
    text-decoration: none;
  }
}

.btn-link-grey {
  @include smallText(14px);
  color: $grey;

  &:hover {
    color: $grey-dark;
    text-decoration: none;
  }
}

.btn-share {
  color: $grey-dark;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    color: $blue;
  }
}

// ==========[ HELPER CLASSES ]==========

.c-grey-dark {
  color: $grey-dark;
}

.c-grey {
  color: $grey;
}

.c-grey-light {
  color: $grey-light;
}

.c-orange {
  color: $orange;
}

.c-mint {
  color: $mint;
}

.c-blue {
  color: $blue;
}

.c-purple {
  color: $purple;
}

.c-green {
  color: $green;
}

.bgc-grey-dark {
  color: $white;
  background-color: $grey-dark;
}

.bgc-grey {
  color: $grey-dark;
  background-color: $grey;
}

.bgc-grey-light {
  color: $grey-dark;
  background-color: $grey-light;
}

.bgc-orange {
  color: $white;
  background-color: $orange;
}

.bgc-mint {
  color: $white;
  background-color: $mint;
}

.bgc-blue {
  color: $white;
  background-color: $blue;
}

.bgc-purple {
  color: $white;
  background-color: $purple;
}

.bgc-green {
  color: $white;
  background-color: $green;
}

.rounded-image {
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: $grey-light;
}

// ==========[ FLIPCARDS ]==========

.flip-card {
  width: 240px;
  height: 240px;
  background-color: transparent;
  perspective: 1000px;

  &.toggle-flipped {
    .flip-card-inner {
      transform: rotateY(180deg);

      @media screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {

        .flip-card-back,
        .flip-card-front {
          transition: opacity 0.5s ease-in;
          opacity: 1;
          backface-visibility: visible;
        }
      }
    }
  }
  
  .fs--sm{
    // font-size: 21px;
    margin: 0 16px;
    word-break: break-word;
    word-wrap: break-word;
  }

}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .flip-card-back {
    opacity: 0;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-back,
.flip-card-front {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 999px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  backface-visibility: hidden;

  h2 {
    font-family: $ff-oregon;
    font-size: 24px;
    font-weight: 100;
    letter-spacing: 0.1em;
    max-width: 100%;
    font-size: 18px;
    font-weight: 700;
  }

  .cms,
  p {
    padding-right: 10px;
    padding-left: 10px;
    max-width: 100%;
    font-size: 14px;
    color: $white;
  }

  &.orange {
    color: $white;
    background-color: $orange;
  }

  &.mint {
    color: $white;
    background-color: $mint;
  }

  &.blue {
    color: $white;
    background-color: $blue;
  }

  &.purple {
    color: $white;
    background-color: $purple;
  }

  &.green {
    color: $white;
    background-color: $green;
  }

  &.yellow {
    color: $white;
    background-color: $yellow;
  }
}

.flip-card-back {
  transform: rotateY(180deg);

  h2 {
    margin: 0px 16px;
    font-size: 16px;
  }
}

// ==========[ CTA ]==========

.cta {
  background-color: $grey-dark;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .overlay {
    background-color: transparentize($grey-dark, 0.15);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }
}

// ==========[ PROGRAMMA BOLLEN KLEIN ]==========

.programma-bol-klein {
  @include quart-transition(400ms);
  display: block;
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 999px;
  background-color: $grey-dark;

  .inner {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h3 {
      color: $white;
      text-align: center;
      font-family: $ff-oregon;
      font-size: 18px;
      font-weight: 100;
      line-height: 24px;
      letter-spacing: 0.1em;
    }

    .btn-link-white {
      @include quart-transition(400ms);
      margin-top: -26px;
      opacity: 0;
    }
  }

  &:hover {
    background-color: $grey;
    text-decoration: none;

    .inner {
      .btn-link-white {
        margin-top: 0;
        opacity: 1;
      }
    }
  }

  &.orange {
    background-color: $orange;

    &:hover {
      background-color: darken($orange, 8%);
    }
  }

  &.blue {
    background-color: $blue;

    &:hover {
      background-color: darken($blue, 8%);
    }
  }

  &.mint {
    background-color: $mint;

    &:hover {
      background-color: darken($mint, 8%);
    }
  }

  &.purple {
    background-color: $purple;

    &:hover {
      background-color: darken($purple, 8%);
    }
  }

  &.green {
    background-color: $green;

    &:hover {
      background-color: darken($green, 8%);
    }
  }

  &.yellow {
    background-color: $yellow;

    &:hover {
      background-color: darken($yellow, 8%);
    }
  }
}

// ==========[ PRIKBORD ]==========

.grid.prikbord-grid {

  .grid-item,
  .grid-sizer {
    width: calc(33% - 20px);
    margin-bottom: 30px;
  }

  .grid-item--width2 {
    width: calc(66% - 20px);
  }

  .prikbord-blok {
    @include quart-transition(400ms);
    height: 100%;
    padding: 40px;
    border: 2px solid $grey;
    border-radius: 10px;
    text-align: center;

    .btn-link-grey,
    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      @include quart-transition(400ms);
    }

    &:hover {
      border: 2px solid $grey-dark;
      background-color: $grey-dark;

      .btn-link-grey,
      .cms,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $white;
      }
    }

    &.orange {
      &:hover {
        border: 2px solid $orange;
        background-color: $orange;
      }
    }

    &.blue {
      &:hover {
        border: 2px solid $blue;
        background-color: $blue;
      }
    }

    &.mint {
      &:hover {
        border: 2px solid $mint;
        background-color: $mint;
      }
    }

    &.purple {
      &:hover {
        border: 2px solid $purple;
        background-color: $purple;
      }
    }

    &.green {
      &:hover {
        border: 2px solid $green;
        background-color: $green;
      }
    }
  }

  .prikbord-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
    }

    .text-content {
      @include quart-transition(400ms);
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 25px;
      opacity: 0;
      background-color: transparentize($white, 0.3);
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      >* {
        color: $grey-dark;
      }
    }

    &:hover {
      .text-content {
        opacity: 1;
      }
    }
  }
}

// ==========[ COÖRDINATOR ITEM ]==========

.coordinator-item {
  height: 100%;
  display: flex;
  padding: 40px;
  border: 2px solid $grey;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .tekst {
    width: 66%;
  }

  .avatar {
    width: 33%;
    padding-top: 33%;
    border-radius: 999px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .link-wrapper {
    display: flex;
    hyphens: auto;

    .fa-w-16 {
      min-width: 1em;
    }
  }

  &:hover {
    border: 2px solid $grey-dark;
    background-color: $grey-dark;

    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      color: $white;
    }

    svg,
    path {
      fill: white;
    }
  }

  &.blue {
    &:hover {
      border: 2px solid $blue;
      background-color: $blue;
    }
  }

  &.orange {
    &:hover {
      border: 2px solid $orange;
      background-color: $orange;
    }
  }

  &.mint {
    &:hover {
      border: 2px solid $mint;
      background-color: $mint;
    }
  }

  &.purple {
    &:hover {
      border: 2px solid $purple;
      background-color: $purple;
    }
  }

  &.green {
    &:hover {
      border: 2px solid $green;
      background-color: $green;
    }
  }
}

// ==========[ HULP ITEM ]==========

.hulp-item {
  @include quart-transition(400ms);
  padding: 40px;
  border: 2px solid $grey;
  border-radius: 10px;

  .btn-link-grey,
  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include quart-transition(400ms);
  }

  &:hover {
    background-color: $grey;

    .btn-link-grey,
    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    a:hover {
      color: $white;
    }
  }

  &.orange {
    border: 2px solid $orange;

    &:hover {
      background-color: $orange;
    }
  }

  &.mint {
    border: 2px solid $mint;

    &:hover {
      background-color: $mint;
    }
  }

  &.blue {
    border: 2px solid $blue;

    &:hover {
      background-color: $blue;
    }
  }

  &.purple {
    border: 2px solid $purple;

    &:hover {
      background-color: $purple;
    }
  }

  &.green {
    border: 2px solid $green;

    &:hover {
      background-color: $green;
    }
  }

  &.yellow {
    border: 2px solid $yellow;

    &:hover {
      background-color: $yellow;
    }
  }
}

// ==========[ GALLERY IMAGE ]==========

.gallery-image {
  width: 100%;
}

// ==========[ SEARCH FIELD ]==========

.quicksearch {
  min-width: 300px;
  height: 50px;
  background-image: url("/dist/assets/images/icon_search.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: auto 24px;

  &::placeholder {
    color: $grey;
  }
}

// ===========[ MIDDLE ] ===========

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  >* {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    // margin: -($item-size / 2) calc($item-size / 16 * -3);
    margin-left:  -($item-size / 16 * 3);
    margin-right:  -($item-size / 16 * 3);
    margin-bottom: -($item-size / 2);
    margin-top: -($item-size / 2);

    $angle: (240 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1.5deg) translate($circle-size / 2) rotate($rot * -1.5deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.circle-container {
  @include on-circle($item-count: 10, $circle-size: 900px, $item-size: 12em);
  margin: 0 auto;
  position: absolute;
  left: -18%;
  top: 2%;
  right: 0;
}

.c-circle-middle {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 500px;
  z-index: 5;
}

// ===========[ LINK OPTION ] ===========

.link-container {
  position: relative;
  margin-top: 50px;
  overflow: hidden;
  background-size: cover;
  border-radius: 10px;

  &__img {
    opacity: 0.84;
  }

  &__content {
    height: 255px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: none;
    transition: background-color 0.2s ease-in;
  }

  .bttn2 {
    @include chose-button();

    font-weight: 700;
    letter-spacing: 1px;
    padding: 20px 40px;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease-in;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
  }

  &:hover {
    text-transform: scale(2);

    .link-container__content {
      background-color: rgba(0, 41, 59, 0.85);
      transition: background-color 0.2s ease-in;
    }

    .bttn2 {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
  }
}

// ===========[ LINK Large ] ===========

.link-large-item {
  @include quart-transition(400ms);
  padding: 40px;
  border: 2px solid $grey;
  border-radius: 10px;
  background-color: $white;
  height: 255px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;

  p {
    text-align: center;
    flex-grow: 1;
  }

  .btn-link-grey,
  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include quart-transition(400ms);
  }

  &:hover {
    border: 2px solid $grey-dark;
    background-color: $grey-dark;

    .btn-link-grey,
    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }
  }

  &.mint {
    &:hover {
      border: 2px solid $mint;
      background-color: $mint;
    }
  }

  &.orange {
    &:hover {
      border: 2px solid $orange;
      background-color: $orange;
    }
  }

  &.blue {
    &:hover {
      border: 2px solid $blue;
      background-color: $blue;
    }
  }

  &.purple {
    &:hover {
      border: 2px solid $purple;
      background-color: $purple;
    }
  }

  &.green {
    &:hover {
      border: 2px solid $green;
      background-color: $green;
    }
  }

  &.yellow {
    &:hover {
      border: 2px solid $yellow;
      background-color: $yellow;
    }
  }

  &.yellow-first {
    border: 2px solid $yellow;
    background-color: $yellow;

    .btn-link-grey,
    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }

    &:hover {
      border: 2px solid $grey;
      background-color: $white;

      .btn-link-grey,
      .cms,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $grey-dark;
      }
    }
  }
}

// ===========[ LINK Organisation ] ===========

.organisation {
  border: 2px solid $grey;
  border-radius: 10px;
  background-color: $white;
  display: flex;
  align-items: center;

  &.standing {
    flex-direction: column;

    .organisation__img {
      width: 100%;
      height: 270px;
      border-bottom: 2px solid $grey;
      border-radius: 10px 10px 0 0;
    }
  }

  &.lying {
    .organisation__img {
      align-self: stretch;
      width: 100%;
      border-right: 2px solid $grey;
      border-radius: 10px 0 0 10px;
    }
  }

  &__img {
    background-color: $grey-light;
    padding: 40px;

    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 250px auto;
  }

  &__content {
    @include quart-transition(400ms);
    padding: 40px;
    color: $grey;
    width: 100%;
    overflow-wrap: break-word;

    .btn-link {
      color: $grey;
    }

    h3 {
      color: $grey-dark;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  &:hover {
    text-decoration: none;

    .organisation__content {
      background-color: $grey;
      border-radius: 0 0 5px 5px;

      .btn-link-grey,
      .cms,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $white;
      }
    }
  }

  &.no-link {
    &:hover {
      cursor: default;
      text-decoration: none;

      .organisation__label,
      .cms {
        color: $grey;

        p {
          color: $grey;
        }
      }

      .organisation__content {
        background-color: $white;
        color: $grey;

        .btn-link {
          color: $grey;
        }

        h3 {
          color: $grey-dark;
        }
      }
    }
  }
}

// ==========[ LENDING SERVICE BOXES ]==========
// nav
.lending_service_boxes {
  .navbar-boxes {
    ul {
      padding: 0;
      list-style-type: none;
    }
  }

  .CTA-boxes {
    a {
      color: $white;
      margin-bottom: 10px;

      &.active,
      &:hover {
        color: $white;
        background-color: $grey-dark;
      }
    }
  }

  .nav-item-boxes {
    margin: 10px 0;

    a {
      @include smallText(13px);
      color: $grey;

      &:hover {
        color: $grey-dark;
        text-decoration: darken($grey-dark, 8%);
      }
    }

    &.active,
    &:hover {
      a {
        color: $grey-dark;
      }
    }
  }
}

// map
.projects-map {
  width: 100%;
  padding-top: 45%;
}

#projects-map__popup {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

// styling lichtpicker (https://github.com/wakirin/Litepicker/blob/master/src/scss/main.scss)
.container__months {
  .day-item {
    margin: 2px;

    &.is-locked {
      background-color: #f6595a;
      color: white !important;
    }
  }
}

// ==========[ BREADCRUMB ]==========
.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  background-color: unset;

  @include smallText(13px);

  a {
    color: $grey;

    &:hover {
      color: $grey-dark;
      text-decoration: darken($grey-dark, 8%);
    }
  }

  &__arrow {
    margin: 0 15px;
  }

  &__current {
    color: $grey;
    color: $grey-dark;
  }
}

// ==========[ VIDEO EMBED ]==========
.vimeo-iframe,
.youtube-iframe,
.other-iframe {
  padding-bottom: 56.67%;
  position: relative;
  width: 100%;
}

.video-iframe {
  height: calc(100% - 60px);
  left: 0;
  position: absolute;
  width: 100% !important;
  margin: 30px 0;
}

.iframe {
  height: 100vh;
  width: 100%;
}