// ==========[ GENERAL ]==========

* {
  font-feature-settings: "kern" 1;
  font-family: $ff-roboto;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: $ff-coranto;
  font-size: 36px;
  font-weight: bold;
}

h2 {
  font-family: $ff-coranto;
  font-size: 32px;
  font-weight: bold;
}

h3 {
  font-family: $ff-coranto;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
}

h4 {
  color: $grey-dark;
  font-family: $ff-coranto;
  font-size: 18px;
  font-weight: bold;
}

h5 {
  @include smallText(14px);
}

h6 {
  color: $grey;
  font-size: 16px;
  font-weight: bold;
}

.cms,
p {
  color: $grey;
  font-size: 16px;

  a {
    color: $grey;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      color: $grey-dark;
      text-decoration: none;
    }
  }

  h1 {
    color: $grey-dark;
    font-family: $ff-coranto;
    font-size: 36px;
    font-weight: normal;
    line-height: 54px;

    b,
    strong {
      font-family: $ff-coranto;
      font-weight: 900;
    }
  }

  h2,
  h3 {
    color: $black;
    font-family: $ff-coranto;
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;

    b,
    strong {
      font-family: $ff-coranto;
      font-weight: 900;
    }
  }

  table {
    border: 1px solid $grey;
  }

  &.mint {
    h1 {
      color: $mint;
    }
  }

  &.orange {
    h1 {
      color: $orange;
    }
  }

  &.blue {
    h1 {
      color: $blue;
    }
  }

  &.purple {
    h1 {
      color: $purple;
    }
  }

  &.green {
    h1 {
      color: $green;
    }
  }
}

// ==========[ FORMS ]==========

.form-group {
  label {
    @include smallText(13px);
    color: $grey;
  }

  .form-control {
    color: $grey-dark;
    border: 2px solid $grey-light;
    border-radius: 0;

    &:focus {
      border: 2px solid $grey;
      outline: 0;
      box-shadow: none;
    }
  }
}

// ==========[ HEADER / NAVIGATIE ]==========

.top-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $grey-light;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top-bar-zoeken {
    padding-left: 30px;
    color: $grey-dark;
    border: 0;
    background-image: url(../images/zoeker.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;

    &::placeholder {
      color: $grey;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  a {
    @include smallText(13px);
    margin-left: 15px;
    color: $grey;

    &:hover {
      color: $grey-dark;
      text-decoration: none;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.navbar {
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 2px solid $grey-light;

  .nav-item {
    margin-left: 10px;

    .nav-link {
      @include smallText(14px);
      color: $grey-dark;
    }

    &.active,
    &:hover {
      .nav-link {
        color: $grey;
      }
    }
  }

  &__logo {
    height: 160px;
  }
}

.sub-nav {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $grey-light;

  a {
    @include smallText(13px);
    padding-right: 10px;
    padding-left: 10px;
    color: $grey;

    &.active,
    &:hover {
      color: $grey-dark;
      text-decoration: none;
    }
  }
}

.dropdown:hover>.dropdown-menu {
  display: block;
  background-color: $grey-dark;
  white-space: nowrap;

  .nav-link {
    color: $white;
    margin: 0 0.5rem;
  }
}

.dropdown.dropdown-detail {
  font-size: 18px;
  width: fit-content;
  &>.dropdown-menu {
    white-space: normal;
    width: 90vw;
  }
}

// ==========[ FOOTER ]==========

footer {
  background-color: #f5f5f5;

  .footer-partners {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      flex: 0 0 33.333%;
    }

    .right {
      display: flex;
      align-items: center;
      flex: 0 0 66.666%;
      justify-content: flex-end;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 5px;

      a {
        @include smallText(14px);
        color: $grey;

        &:hover {
          color: $grey-dark;
          text-decoration: none;
        }
      }
    }
  }

  .divider {
    background-color: #e6dede;
  }

  .footer-social {
    color: $grey-dark;
    font-size: 32px;

    &:hover {
      color: $grey;
      text-decoration: none;
    }
  }

  .subfooter {
    p {
      @include smallText(13px);
    }
  }
}

// ==========[ HOME ]==========

.home-slider {
  position: relative;
  width: 100%;
  height: 400px;
  overflow-x: hidden;

  .slider-controls {
    display: flex;
    position: absolute;
    top: calc(50% - 21px);
    right: 40px;
    left: 40px;
    align-items: center;
    font-size: 28px;
    justify-content: space-between;

    a {
      z-index: 999;
      color: transparentize($white, 0.5);

      &:hover {
        color: $white;
      }
    }
  }

  .slide {
    display: block;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .overlay {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 30px 40px;
      background: linear-gradient(to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.5) 100%);
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;

      h3 {
        color: $white;
      }

      .slide-datum {
        color: $grey;
        font-size: 16px;
        font-weight: bold;
      }
    }

    &:hover {
      text-decoration: none;

      .overlay {
        h3 {
          color: $orange;
        }
      }
    }
  }
}

// ==========[ NIEUWS ]==========

.nieuws-item {
  display: block;
  overflow: hidden;
  border: 2px solid $grey;
  border-radius: 10px;

  h3 {
    color: $grey-dark;
  }

  .image {
    width: 100%;
    padding-top: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    @include quart-transition(400ms);
    padding: 40px;
    background-color: $white;

    .datum-nieuws {
      color: $grey;
      font-size: 16px;
      font-weight: bold;
    }

    .btn-link-grey,
    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      @include quart-transition(400ms);
    }
  }

  &:hover {
    text-decoration: none;

    .content {
      background-color: $grey;

      .btn-link-grey,
      .cms,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $white;
      }
    }
  }
}

// ==========[ NETWERK RADAR ]==========

.map-radar {
  width: 100%;
  height: 500px;
}

.grid.document-grid {

  .grid-item,
  .grid-sizer {
    width: calc(50% - 20px);
    margin-bottom: 30px;
  }
}

.document-item {
  @include quart-transition(400ms);
  padding: 40px;
  border: 2px solid $grey;
  border-radius: 10px;
  background-color: $white;

  .document-datum {
    color: $grey;
    font-size: 14px;
    font-weight: bold;
  }

  .btn-link-grey,
  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include quart-transition(400ms);
  }

  &:hover {
    border: 2px solid $grey-dark;
    background-color: $grey-dark;

    .btn-link-grey,
    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }
  }

  &.mint {
    &:hover {
      border: 2px solid $mint;
      background-color: $mint;
    }
  }

  &.orange {
    &:hover {
      border: 2px solid $orange;
      background-color: $orange;
    }
  }

  &.blue {
    &:hover {
      border: 2px solid $blue;
      background-color: $blue;
    }
  }

  &.purple {
    &:hover {
      border: 2px solid $purple;
      background-color: $purple;
    }
  }

  &.green {
    &:hover {
      border: 2px solid $green;
      background-color: $green;
    }
  }
}

// ==========[ PROGRAMMA DETAIL ]==========

.programma-header {
  position: relative;

  .image {
    width: 800px;
    height: 400px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
  }

  .bol {
    display: flex;
    position: absolute;
    top: calc(200px - (270px / 2));
    right: 0;
    width: 270px;
    height: 270px;
    border-radius: 999px;
    background-color: $grey-dark;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
      text-align: center;
      width: 100%;
    }

    &.mint {
      background-color: $mint;
    }

    &.blue {
      background-color: $blue;
    }

    &.orange {
      background-color: $orange;
    }

    &.purple {
      background-color: $purple;
    }

    &.green {
      background-color: $green;
    }

    &.yellow {
      background-color: $yellow;
    }

    h2 {
      font-size: 24px
    }
  }
}

// ==========[ FAQ ]==========

.faq-vraag {
  @include quart-transition(400ms);
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 40px;
  border: 2px solid $grey;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  h4 {
    @include quart-transition(400ms);
  }

  i,
  svg {
    @include quart-transition(400ms);
    color: $grey;
  }

  &.active,
  &:hover {
    background-color: $grey;

    h4 {
      color: $white;
    }

    i,
    svg {
      color: $white;
    }
  }

  &.active {

    i,
    svg {
      transform: rotate(180deg);
    }
  }
}

.faq-antwoord {
  display: none;
  padding: 40px;
}

// ==========[ LINKS ]==========

.links-title {
  margin: 0 0 50px 0;
}

.links-wrapper {
  margin-bottom: 100px;
}

.link-item {
  .image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 10px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;

    .inner {
      @include quart-transition(400ms);
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 40px;
      opacity: 0;
      background-color: $grey;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .cms {
        color: $white;
      }
    }

    &:hover {
      .inner {
        opacity: 1;
      }
    }
  }

  &.link-item-half {
    .image {
      padding-top: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  &.link-item-contain {
    .image {
      background-size: 250px auto;
    }
  }
}

.link-item2 {
  display: flex;
  margin: 15px 0;
  padding: 40px 0 40px 40px;
  border: 2px solid $grey;
  border-radius: 10px;
  background-color: $white;

  &__img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-left: 25px;
    padding: 20px;
  }

  &__content_warpper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
  }

  .link-item2-link {
    margin-top: 24px;
  }
}

// ==========[ INTERNAL ]==========

.nav-internal {
  padding: 20px 0;
  background-color: $grey-light;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      margin-right: 15px;
      color: $grey;
      text-transform: uppercase;
      font-family: $ff-roboto;
      font-size: 13px;
      font-weight: bold;
      line-height: 14px;
      letter-spacing: 0.1em;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $grey-dark;
        text-decoration: none;
      }
    }
  }
}

.internal-sidebar {
  padding: 20px;
  border: 2px solid $grey;
  border-radius: 10px;

  .folder-header {
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: flex-start;

    a {
      @include quart-transition(400ms);
    }

    .folder-name {
      display: flex;
      margin-left: 10px;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      img {
        margin-right: 10px;
      }

      p {
        color: $grey-dark;
        text-transform: uppercase;
        font-family: $ff-roboto;
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        letter-spacing: 0.1em;
      }

      &.active-filter {
        p {
          color: $mint;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &.active {
      a {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    &.no-children {
      padding-left: 8px;

      a {
        display: none;
      }
    }
  }

  .folder-content {
    display: none;
    padding-top: 15px;
    padding-left: 20px;
  }
}

// ==========[ LOGIN ]==========

#LoginRegister {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .InputfieldForm {
    @include softshadow;
    min-width: 50%;
    padding: 50px 60px;
    border-radius: 2px;
    background-color: $white;

    h2 {
      margin-bottom: 25px;
    }

    .Inputfield {
      min-width: 50%;
      margin-top: 15px;

      .InputfieldHeader {
        color: $grey-dark;
        text-transform: uppercase;
        font-family: $ff-roboto;
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        letter-spacing: 0.1em;
      }

      .InputfieldContent {
        min-width: 50%;

        input {
          width: 100%;
          min-height: 40px;
          padding: 0px 10px;
          color: $grey-dark;
          border: 2px solid $grey-light;
          border-radius: 0;

          &:focus {
            border: 2px solid $grey;
            outline: 0;
            box-shadow: none;
          }
        }

        button {
          @include smallText(14px);
          @include normal-button($grey-dark);
          margin-top: 15px;
          padding: 14px 21px;
          border-radius: 10px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .LoginRegisterLinks {
    margin-top: 35px;
    padding: 0;
    list-style-type: none;

    a {
      color: $grey;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
      letter-spacing: 0.1em;

      &:hover {
        color: $grey-dark;
        text-decoration: none;
      }
    }
  }
}

p.description {
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $white;

  &>div {
    width: 300px;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
    }

    input[type="password"] {
      margin-bottom: 10px;
      padding: 7px 15px;
    }
  }
}

.text-break{
  word-wrap: break-word;
  word-break: break-word;
}