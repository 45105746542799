// ==========[ FONTS ]==========

@font-face {
  font-family: 'Oregon';
  src: url(/dist/assets/fonts/oregon-webfont.eot);
  src: url(/dist/assets/fonts/oregon-webfont.ttf);
  src: url(/dist/assets/fonts/oregon-webfont.woff);
  src: url(/dist/assets/fonts/oregon-webfont.woff2);
}

$ff-coranto : 'coranto-2', serif;
$ff-roboto  : 'Roboto', sans-serif;
$ff-oregon  : 'Oregon', sans-serif;

// ==========[ COLORS ]==========

$white         : #FFFFFF;
$grey-light    : #F5F5F5;
$grey          : #5b717b;
$grey-dark     : #00293B;
$black         : #000000;

$orange        : #EA603D;
$mint          : #69BDAC;
$blue          : #2B628A;
$purple        : #70559B;
$green         : #05A45B;
$yellow        : #ddb10a;

$primary-color-404   : rgba(116,235,213,1);
$secondary-color-404 : rgba(172,182,229,1);

// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

@mixin smallText($fontsize) {
  font-size: $fontsize;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin normal-button($color) {
  background-color: $color;
  border: 2px solid $color;
  color: $white;

  &:hover {
    background-color: darken($color, 8%);
    border: 2px solid darken($color, 8%);
    color: $white;
  }
}

@mixin border-button($color) {
  background-color: transparent;
  border: 2px solid $color;
  color: $color;

  &:hover {
    background-color: $color;
    color: $white;
    border: 2px solid $color;
  }
}

@mixin filter-button($color) {
  background-color: transparent;
  color: $color;
  border: 2px solid $color;

  &:hover {
    background-color: $color;
    color: $white;
    border: 2px solid $color;
  }
}

@mixin chose-button() {
  background-color: transparent;
  color: $white;
  border: 2px solid $grey;

  &:hover {
    background-color: $white;
    color: $grey-dark;
    border: 2px solid $white;
    text-decoration: none;
  }
}
