// Extra small devices (portrait phones, less than 576px)

@media (max-width: 575.98px) {
  .off-canvas-nav {
    right: -100vw;
    width: 100vw;
  }

  .grid.document-grid {
    .grid-item,
    .grid-sizer {
      width: 100%;
    }
  }

  .programma-header {
    .image {
      width: 100%;
      height: 300px !important;
    }

    .bol {
      top: calc(150px - (225px / 2));
      right: calc(50% - (225px / 2)) !important;
      width: 225px !important;
      height: 225px !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-wrap: break-word;
  }

  .grid.prikbord-grid {
    .grid-item,
    .grid-sizer {
      width: 100% !important;
    }

    .grid-item--width2 {
      width: 100% !important;
    }
  }

  .link-item {
    flex-direction: column;
    align-items: center;

    &__img {
      margin: 0;
    }
  }

  .link-item2 {
    flex-direction: column-reverse;
    padding: 0 40px 40px;

    &__img {
      margin: 0;
      align-self: center;
    }
  }

  .breadcrumb {
    @include smallText(11px);

    a,
    li {
      padding: 10px 0;
    }

    &__arrow {
      margin: 0 7px;
    }
  }

  .navbar {
    &__logo {
      height: 80px;
    }
  }
}

// Small devices (landscape phones, less than 768px)

@media (max-width: 767.98px) {
  footer {
    .footer-partners {
      flex-direction: column;
    }
  }

  .link-container__content {
    background-color: rgba(0, 41, 59, 0.85);
  }

  .link-container {
    .bttn2 {
      opacity: 1;
    }
  }

  .organisation {
    &.lying {
      flex-direction: column;

      .organisation__img {
        width: 100%;
        height: 270px;
        border-bottom: 2px solid $grey;
        border-right: none;
        border-radius: 10px 10px 0 0;
      }
    }
  }

  .flip-card {
    width: 265px;
    height: 265px;
  }
}

// Medium devices (tablets, less than 992px)

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .programma-header {
    .image {
      width: 100%;
      height: 400px;
    }

    .bol {
      right: calc(50% - (270px / 2));
    }
  }

  .grid.prikbord-grid {
    .grid-item,
    .grid-sizer {
      width: calc(50% - 20px);
    }

    .grid-item--width2 {
      width: calc(50% - 20px);
    }
  }
}

// Large devices (desktops, less than 1200px)

@media (max-width: 991.98px) {
  .flip-card {
    width: 100%;
    height: unset;
    aspect-ratio: 1;
  }
}
