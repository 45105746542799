.cookie-banner{
	box-shadow: 0px 3px 15px rgba(black, 0.2);
	z-index: 10;
    display: flex;

	p{
		font-size: 14px;
		line-height: 125%;

		a{
			color: $grey;
			text-decoration: underline;

			&:hover{
				color: darken($grey, 15);
			}
		}
	}

	.button-wrap{
		min-width: 30%;
	}

	.button{
		margin: 2px;
		margin-left: 12px;
		padding: 10px;
		text-align: center;
		vertical-align: middle;
	}

    .uk-button-primary {
        background-color: blue;
        color           : white;

        &:hover{
        	background-color: darken(blue, 5);
        }
    }

	#cookie_form{
		align-items: center;
		background-color: rgba(0,0,0,0.2);
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		padding: 24px;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 9;

		.form-url{
			display: none;
		}

		.cookie-banner-popup{
			background: #ffffff;
			box-shadow: 0px 3px 15px rgba(black, 0.2);
			display: flex;
			flex-direction: column;
			max-height: calc(100vh - 48px);
			max-width: 860px;
			width: 100%;

			&__bottom{
				border-top: 1px solid #dddddd;
				padding: 10px 20px;
			}

			&__top{
			overflow: auto;
				padding: 20px;
				flex: 1;
			}
		}
	}
}

.uk-switch {
	position: relative;
	display : inline-block;
	height  : 22px;
	width   : 40px;
}

.uk-switch input {
	display: none;
}

.uk-switch-slider {
	background-color   : rgba(0, 0, 0, 0.22);
	position           : absolute;
	top                : 0;
	left               : 0;
	right              : 0;
	border-radius      : 500px;
	bottom             : 0;
	cursor             : pointer;
	transition-property: background-color;
	transition-duration: .2s;
	box-shadow         : inset 0 0 2px rgba(0, 0, 0, 0.07);
}

.uk-switch-slider:before {
	content            : '';
	background-color   : #fff;
	position           : absolute;
	width              : 22px;
	height             : 22px;
	left               : 0px;
	bottom             : 0px;
	border-radius      : 50%;
	transition-property: transform, box-shadow;
	transition-duration: .2s;
}

.uk-switch-disabled {
	cursor: initial;
}

input:checked+.uk-switch-slider {
	&:not(.uk-switch-disabled) {
		background-color: #39f !important;
	}
}

input:checked+.uk-switch-slider:before {
transform: translateX(18px);
}

.uk-switch-slider.uk-switch-on-off {
	background-color: #f0506e;
}

input:checked+.uk-switch-slider.uk-switch-on-off {
	background-color: #32d296 !important;
}

.uk-switch-slider.uk-switch-big:before {
	transform : scale(1.2);
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

.uk-switch-slider.uk-switch-small:before {
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

input:checked+.uk-switch-slider.uk-switch-big:before {
	transform: translateX(26px) scale(1.2);
}

.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
	background-color: rgba(255, 255, 255, 0.22);
}


@media only screen and (max-width: 961px){
	.cookie-banner{
		text-align: center;
		p{
			font-size: 12px;
			text-align: center !important;
		}

		.button{
			font-size: 12px;
		}
	}
}