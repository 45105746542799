$primary_color   : #17B3B0;
$secondary_color : #3487C7;
$splash_color    : #55EFC4;
$text_color      : #2D3436;
$title_font      : 'Montserrat', sans-serif;
$body_font       : 'Esteban', serif;

// ==========[ COMING SOON PAGE ]==========

.wrapper__coming-soon {
  display             : flex;
  width               : 100vw;
  height              : 100vh;
  background          : $grey-light;
  background-repeat   : no-repeat;
  background-position : center;
  background-size     : cover;
  align-items         : center;
  justify-content     : center;

  .inner__coming-soon {
    max-width        : 500px;
    padding          : 40px;
    border-radius    : 6px;
    background-color : #FFF;
    box-shadow       : 0 50px 100px rgba(0, 0, 0, .1);

    .logo__coming-soon {
      width               : 150px;
      height              : 150px;
      margin              : 0px auto 20px;
      background-repeat   : no-repeat;
      background-position : center;
      background-size     : cover;
    }

    .title__coming-soon {
      margin-bottom : 15px;
      color         : $text_color;
      text-align    : center;
      font-family   : $title_font;
      font-size     : 24px;
      font-weight   : bold;
      line-height   : 38px;
    }

    .text__coming-soon {
      color       : lighten($text_color, 33%);
      font-family : Helvetica,
                    sans-serif;
      font-size   : 15px;
      font-weight : 500;
      line-height : 25px;

      a {
        color           : $primary_color;
        text-decoration : underline;

        &:hover {
          color           : $text_color;
          text-decoration : none;
        }
      }
    }

    .contact__coming-soon {
      margin-top : 35px;

      h2 {
        margin-bottom : 10px;
        color         : $text_color;
        font-family   : $title_font;
        font-size     : 18px;
        font-weight   : bold;
      }
    }

    .socials__coming-soon {
      display         : flex;
      margin-top      : 35px;
      align-items     : center;
      justify-content : space-between;

      h2 {
        color       : lighten($text_color, 50%);
        font-family : $title_font;
        font-size   : 18px;
        font-weight : bold;
      }

      .socials {
        display         : flex;
        align-items     : center;
        justify-content : flex-end;

        a {
          margin-right : 10px;
          color        : lighten($text_color, 50%);
          font-size    : 18px;

          &:last-child {
            margin-right : 0;
          }

          &:hover {
            color           : $primary_color;
            text-decoration : none;
          }
        }
      }
    }
  }
}
